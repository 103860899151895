import Vue from "vue";
import Router from "vue-router";

// Importar vistas
const home = () => import('@/views/Home/HomeIndex');
const finder = () => import('@/views/Finder/FinderIndex');
const book = () => import('@/views/Book/BookIndex');
const bible = () => import('@/views/Bible/BibleIndex');
const music = () => import('@/views/Music/MusicIndex');
const bookReader = () => import('@/views/BookReader/BookReaderIndex');
const bookEpubReader = () => import('@/views/BookEpubReader/BookEpubReaderIndex');
const categories = () => import('@/views/Categories/CategoriesIndex');
const booksByCategory = () => import('@/views/BooksByCategory/BooksByCategoryIndex');


Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: home
    },
    {
      path: "/book/:bookID",
      name: 'Book',
      props: true,
      component: book
    },
    {
      path: "/bookreader/:bookID",
      name: 'BookReader',
      props: true,
      component: bookReader
    },
    {
      path: "/bookEpubreader/:bookID",
      name: 'BookEpubReader',
      props: true,
      component: bookEpubReader
    },
    {
      path: "/finder",
      name: 'Finder',
      props: true,
      component: finder
    },
    {
      path: "/categories",
      name: 'Categories',
      props: true,
      component: categories
    },
    {
      path: "/booksbycategory",
      name: 'BooksByCategory',
      props: true,
      component: booksByCategory
    },
    {
      path: "/bible",
      name: 'Evangelio',
      props: true,
      component: bible
    },
    {
      path: "/music",
      name: 'Music',
      props: true,
      component: music
    },
  ]
});
